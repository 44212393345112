/*
 * @Author:  
 * @Date: 2023-06-28 09:41:16
 * @LastEditors:  
 * @LastEditTime: 2023-06-28 21:06:47
 * @Description: 
 */
// createWebHistory

import { createRouter, createWebHashHistory } from 'vue-router'
// import Layout from '@/layout'


const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/layout') ,
    redirect: '/index',
    meta: {
      title: '账户列表'
    },
    children:[
      {
        path: "index",
        component: () => import("@/views/index"),
        meta: {
          title: '首页'
        }
      ,
    }]
  },
  {
    path:"/login",
    name:'login',
    component: () => import("@/views/login"),
  }

]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
    history: createWebHashHistory(),
  routes
})


router.beforeEach((before,after)=> {

 
  console.log(before,after,)
})













export default router
