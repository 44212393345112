<!--
 * @Author:  
 * @Date: 2023-06-28 09:41:16
 * @LastEditors:  
 * @LastEditTime: 2023-06-28 13:47:56
 * @Description: 
-->
<template>


<router-view />


</template>
<script  setup>



</script>
<style scoped>
</style>
