import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 全局引入arco.design ui框架  
import ArcoVue from '@arco-design/web-vue';
import '@arco-design/web-vue/dist/arco.css';


createApp(App).use(store).use(router).use(ArcoVue).mount('#app')
